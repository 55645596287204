<template>
  <div class="flex justify-center">
    <section class="box w-1/2 p-4 flex flex-col items-center gap-4">
      <span class="font-bold text-xl">Actualizar Actuación</span>
      <div class="flex w-full gap-4">
        <div class="flex flex-col w-full">
          <span class="text-xs">Tipo:</span>
          <Dropdown class="w-full"
                    :options="listActuacionesTipos"
                    option-label="nombre"
                    option-value="id"
                    placeholder="Seleccione tipo"
                    v-model="model.tipo"
          />
          <MessageError :text="errors.tipo"/>
        </div>
        <div class="flex flex-col w-full">
          <span class="text-xs">Fecha Actuación:</span>
          <input type="date" v-model="model.fechaActuacion" class="w-full p-inputtext"/>
          <MessageError :text="errors.fechaActuacion"/>
        </div>
      </div>

      <div class="flex w-full gap-4">
        <div class="flex flex-col w-full">
          <span class="text-xs">Fecha Inicia Termino:</span>
          <input type="date" v-model="model.fechaIniciaTermino" class="w-full p-inputtext"/>
          <MessageError :text="errors.fechaIniciaTermino"/>
        </div>
        <div class="flex flex-col w-full">
          <span class="text-xs">Fecha Finaliza Termino:</span>
          <input type="date" v-model="model.fechaFinalizaTermino" class="w-full p-inputtext"/>
          <MessageError :text="errors.fechaFinalizaTermino"/>
        </div>
      </div>

      <div class="flex flex-col w-full">
        <span class="text-xs">Actuación:</span>
        <Textarea v-model="model.anotacion" :autoResize="true" rows="1" cols="30"/>
        <MessageError :text="errors.anotacion"/>
      </div>

      <div class="flex flex-col w-full">
        <span class="text-xs">Observaciones:</span>
        <Textarea v-model="model.observaciones" :autoResize="true" rows="3" cols="30"/>
        <MessageError :text="errors.observaciones"/>
      </div>

      <div class="flex flex-col w-full">
        <template v-if="adjuntos.length > 0">
          <div class="flex justify-between">
            <section>
              <i class="pi pi-file-pdf mr-2 text-black"></i>
              <span v-for="(item, key) in adjuntos" :key="key">{{ item.fileName }}</span>
            </section>
            <div class="">
              <button @click="quitarPdf(key)"><i class="pi pi-trash mr-2 text-red-600"></i></button>
            </div>
          </div>
        </template>
        <button label="Show" icon="pi pi-external-link" @click="openMaximizable"
                class="w-full rounded-full py-2 bg-white border border-solid border-gray-400 mt-4">
          Agregar nuevo soporte
        </button>
      </div>

      <div class="flex justify-between w-full">
        <Button label="Atras" class="p-button-outlined" @click="$router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones'})"/>
        <Button label="Guardar" @click="save"/>
      </div>
    </section>
  </div>

  <Dialog header="Agregar soportes" v-model:visible="displayMaximizable"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <FilePond ref="pond"/>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus/>
    </template>
  </Dialog>
</template>

<script>
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { computed, onMounted, ref } from 'vue'
import ProcesosJudicialesStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/procesosJudiciales.store'
import FilePond from '../../../../../uploadFilePoundNew/index.vue'
import FilePondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'
// import dayjs from 'dayjs'
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'crearActuacion',
  components: {
    FilePond
  },
  setup () {
    const adjuntos = ref([])
    const displayMaximizable = ref(false)
    const title = ref('')
    const pond = ref()
    const router = useRouter()
    const route = useRoute()

    const listActuacionesTipos = computed(() => ProcesosJudicialesStore.getters.getListActuacionesTipos.rows)
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )

    const schema = yup.object({
      tipo: yup.string().nullable().required('El campo es requerido').label(''),
      fechaActuacion: yup.date().nullable().required('El campo es requerido').label(''),
      fechaIniciaTermino: yup.string().nullable().required('El campo es requerido').label(''),
      fechaFinalizaTermino: yup.string().when('tipo', {
        is: (val) => {
          return val !== '4'
        },
        then: yup.string().required('Este campo es requerido'),
        otherwise: yup.string()
      }),
      anotacion: yup.string().nullable().required('El campo es requerido').label(''),
      observaciones: yup.string().nullable().required('El campo es requerido').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('tipo')
    useField('fechaActuacion')
    useField('fechaIniciaTermino')
    useField('fechaFinalizaTermino')
    useField('anotacion')
    useField('observaciones')

    const save = handleSubmit((values) => {
      values.paths = adjuntos.value
      values.procesoJudicialId = route.params.id
        if (values.fechaFinalizaTermino === '') {
          values.fechaFinalizaTermino = null
        }
        ProcesosJudicialesStore.dispatch('actionUpdateActuacion', { id: route.params.idActuacion, payload: values }).then(() => {
        router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones' })
      })
    })
    const quitarPdf = (key) => {
      adjuntos.value.splice(key, 1)
    }

    const openMaximizable = () => {
      displayMaximizable.value = true
    }
    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }

    onMounted(() => {
      ProcesosJudicialesStore.dispatch('actionActuacionesTipos')
      ProcesosJudicialesStore.dispatch('actionFindActuacion', route.params.idActuacion).then((data) => {
        console.log('aquiii', data)
        model.tipo = data.tipo
        model.fechaActuacion = data.fechaActuacion
        model.fechaIniciaTermino = data.fechaIniciaTermino
        model.fechaFinalizaTermino = data.fechaFinalizaTermino === 'No tiene' ? '' : data.fechaFinalizaTermino
        model.anotacion = data.anotacion
        model.observaciones = data.observaciones
        adjuntos.value = data.paths
      })
    })

    return {
      listActuacionesTipos,
      errors,
      model,
      save,
      adjuntos,
      displayMaximizable,
      title,
      pond,
      quitarPdf,
      openMaximizable,
      closeMaximizable
    }
  }
}
</script>

<style scoped>

</style>
